<template>
  <div class="app-container">
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
</style>
